<template>
<div class="product" @click="onClick">
    <div class="img">
      <img :src="img" v-show="img">
    </div>
    <span class="title">{{name}}</span>
</div>
</template>


<script>
export default {
  name: 'BrandBox',
  props: {
    name: {
      type: String,
      required: true
    },
    img: {
      type: String,
      required: true
    },
    desc: {
      type: String,
      required: true
    },
    model: {
      type: String
    }
  },
  methods: {
    onClick() {
      this.$emit('boxClick', this.name, this.img, this.desc, this.model)
    },
  }
}
</script>

<style scoped>

.product {
  cursor: default;
  background-color: white;
  text-align: center;
  margin-left: 30px;
  margin-bottom: 30px;
  width: 170px;
  height: 170px;
  transition: top .7s;
  position: relative;
  top: 0px;
  border-radius: 10px;
	max-width: 270px;
	box-shadow: 0px 1px 4px #e8e8e8,
				0px 0px 20px #e8e8e8;
}

.product:hover {
  top: -10px;
}

.product .img {
  width: 170px;
  height: 110px;
}

.product .title {
  font-size: 13px;
  margin-top: 5px;
  display: block;
}

.img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

</style>