<template>
  <div class="home">
    <div class="zone">
      <div class="zone-title conten">品牌列表</div>
      <div class="products conten">
        <BrandBox
          v-for="(brand, index) in brands"
          v-bind:key="index"
          :name="brand.name"
          :img="brand.logo"
          :desc="brand.desc"
          :model="brand.model"
          @boxClick="onBrandBoxClick"
        />
      </div>
    </div>

    <div class="popup" v-show="popupStatus">
      <div class="content conten">
        <div class="title">{{ popupTitle }}</div>
         <div class="info">
            <div class="left"><img :src="popupImg" /></div>
            <div class="right">
              <div>联系方式</div>
              <div><Icon name="icon-phone" /> 0517-89881150</div>
              <div><Icon name="icon-mail" /> 18724164506@163.com</div>
              <div>
                <Icon name="icon-location" />
                江苏省淮安市经济技术开发区枚皋路19号<br />&nbsp;&nbsp;&nbsp;&nbsp;智慧谷B2号楼109室
              </div>
            </div>
          </div>
        <div class="cc">
          <div class="zone">
            <div class="zone-title conten">品牌简介</div>
            <div class="conten" v-html="popupHtml"></div>
          </div>
          <div class="zone">
            <div class="zone-title conten">型号列表</div>
            <div class="conten" v-html="popupModelHtml"></div>
          </div>
        </div>
      </div>
      <div class="over" @click="closePopup" />
    </div>
  </div>
</template>

<script>
import Icon from '@/components/Icon'
import BrandBox from "@/components/BrandBox";
import axios from "axios";

function removeClass(el, cls) {
  if (!el || !cls) return;
  const classes = cls.split(" ");
  let curClass = ` ${el.className} `;

  for (let i = 0, j = classes.length; i < j; i++) {
    const clsName = classes[i];
    if (!clsName) continue;

    if (el.classList) {
      el.classList.remove(clsName);
    } else if (hasClass(el, clsName)) {
      curClass = curClass.replace(` ${clsName} `, " ");
    }
  }
  if (!el.classList) {
    el.className = trim(curClass);
  }
}

function addClass(el, cls) {
  if (!el) return;
  let curClass = el.className;
  const classes = (cls || "").split(" ");

  for (let i = 0, j = classes.length; i < j; i++) {
    const clsName = classes[i];
    if (!clsName) continue;

    if (el.classList) {
      el.classList.add(clsName);
    } else if (!hasClass(el, clsName)) {
      curClass += ` ${clsName}`;
    }
  }
  if (!el.classList) {
    el.className = curClass;
  }
}

export default {
  name: "Home",
  data() {
    return {
      brands: [],
      popupStatus: false,
      popupDesc: "",
      popupTitle: "",
      popupImg: "",
      popupHtml: "",
      popupModelHtml: "",
    };
  },
  components: {
    BrandBox,Icon
  },
  mounted() {
    this.$nextTick(function () {
      this.getBrands();
    });
  },
  methods: {
    getBrands() {
      let _this = this;
      axios.get("brands.json").then(function (resp) {
        _this.brands = resp.data;
      });
    },
    closePopup() {
      this.popupStatus = false;
      removeClass(document.body, "popup-parent--hidden");
    },
    onBrandBoxClick(name, img, desc, model) {
      if (desc != this.popupDesc) {
        this.popupDesc = desc;
        this.popupTitle = name;
        this.popupImg = img;
        let _this = this;
        axios.get(desc).then(function (resp) {
          _this.popupHtml = resp.data;
          axios.get(model).then(function (resp) {
            _this.popupModelHtml = resp.data;
            _this.popupStatus = true;
            addClass(document.body, "popup-parent--hidden");
          });
        });
      } else {
        this.popupStatus = true;
        addClass(document.body, "popup-parent--hidden");
      }
    },
  },
};
</script>

<style scoped>
.home {
  background-color: #f5f5f5;
}

.zone {
  margin-top: 40px;
  background: #fff;
  padding: 60px;
  clear: both;
}

.zone-title {
  margin-top: -30px;
  text-align: center;
  font-size: 30px;
  margin-bottom: 40px;
}

.zone-title::after {
  content: " ";
  display: block;
  border-bottom: 3px solid #7095d3;
  width: 70px;
  margin: 10px auto;
}

.products {
  display: flex;
  flex-wrap: wrap;
}

.box {
  display: flex;
  justify-content: space-between;
}

.popup .title {
  text-align: center;
  color: white;
  padding: 15px;
  background-color: #2e5495;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.popup .content {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 1000;
  background-color: white;
  border-radius: 10px;
  transform: translate(-50%, -50%);
  box-shadow: 0px 1px 4px #cccccc, 0px 0px 20px #cccccc;
}

.popup .content .cc {
  text-align: left;
  overflow: auto;
  min-height: 400px;
  max-height: 500px;
}

.popup .over {
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #c7b2b2;
}

.popup .info {
  display: flex;
  justify-content: space-between;
  background-color: #0073aa;
  padding: 0 50px;
}

.popup .info .left {
  width: 270px;
  height: 150px;
}

.popup .info .left img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.popup .info .right {
  margin-left: 20px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
</style>