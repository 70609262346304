<template>
  <div class="footer">
    <div class="line1 conten">

      <div class="customerservice">
        <Icon class="customerservice-icon" name="icon-customerservice"/>
        <div class="customerservice-info">
          <div><Icon name="icon-phone"/> 0517-89881150</div>
          <div><Icon name="icon-mail"/> 18724164506@163.com</div>
          <div><Icon name="icon-location"/> 江苏省淮安市经济技术开发区枚皋路19号<br>&nbsp;&nbsp;&nbsp;&nbsp;智慧谷B2号楼109室</div>
        </div>
      </div>

      <!-- <div class="links">
        <div>关于我们</div>
        <div>倍鑫特简介</div>
        <div>发展历程</div>
        <div>公司愿景</div>
        <div>公司业务</div>
      </div>
      <div class="links">
        <div>服务流程</div>
        <div>订货流程</div>
        <div>付款信息</div>
        <div>发货流程</div>
        <div>开票流程</div>
      </div>
      <div class="links">
        <div>售后服务</div>
        <div>质保政策</div>
        <div>货物检验</div>
        <div>返修/退换货</div>
        <div>物流包装</div>
      </div>
      <div class="links">
        <div>联系我们</div>
        <div>倍鑫特淮安</div>
      </div> -->
      <!-- <div class="links">
        <div>网站条款</div>
        <div>版权保护</div>
        <div>销售条款</div>
        <div>投诉与建议</div>
      </div> -->
    </div>

    <div class="line2 conten">
      Copyright © 2019-2021 倍鑫特 版权所有 | <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备17072893号-3</a>
    </div>
    
  </div>
</template>

<script>
import Icon from '@/components/Icon'

export default {
  components: {
    Icon
  }
}
</script>

<style scoped>
.footer {
  background-color: #404349;
  color: #fff;
  padding-bottom: 20px;
  margin-right: -17px;
}

.line1 {
  display: flex;
  justify-content: space-between;
  padding: 40px 15px;
}

.customerservice {
  display: flex;
  padding-right: 80px;
  border-right: 1px solid #535353;
}

.customerservice-icon {
  font-size: 50px;
}

.customerservice-info {
  margin-left: 20px;
  color: #bbb;
}

.customerservice-info > div {
  margin-bottom: 10px;
}

.links > div {
  font-size: 12px;
  line-height: 22px;
  color: #9b9ea0;
}

.links > div:first-of-type {
  font-size: 14px;
  color: #d7d8d9;
  margin-bottom: 14px;
}

.line2, .line2 * {
  text-align: center;
  font-size: 14px;
  color: #888888;
}

</style>
