<template>
  <div class="header">
    <div class="line1 conten">
        <div class="logo">
          <img src="@/assets/logo.png" alt="">
        </div>
        <div class="nav">
          <router-link to="/" class="nav-item">
            <div class="nav-t">首页</div>
            <div class="nav-e">Home</div>
          </router-link>
          <!-- <router-link to="/product" class="nav-item">
            <div class="nav-t">品牌中心</div>
            <div class="nav-e">Product</div>
          </router-link>
          <router-link to="/product" class="nav-item">
            <div class="nav-t">产品中心</div>
            <div class="nav-e">Product</div>
          </router-link> -->
          <!-- <router-link to="/about" class="nav-item">
            <div class="nav-t">联系我们</div>
            <div class="nav-e">About Us</div>
          </router-link> -->
        </div>
      </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
}
</script>

<style scoped>
.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 80px;
  background: #2e5495;
  z-index: 10;
}

.popup-parent--hidden .header {
  left: -17px;
}

.line1 {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.logo > img {
  height: 80px;
}

.nav {
  display: flex;
}

.nav-item {
  cursor: pointer;
  position: relative;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 30px;
  min-width: 60px;
  text-align: center;
  text-decoration: none;
  transition: display .5s;
}

.nav-t {
  display: block;
  color: #fff;
  padding-top: 30px;
  text-decoration: none;
}
    
.nav-e {
  color: #fff;
  display: none;
  font-size: 10px;
}

.nav-item::before {
    height: 2px;
    position: absolute;
    left: 0;
    width: 0;
    bottom: 0;
    background: #fff;
    content: " ";
    transition: width .5s;
}

.nav-item:hover::before {
    width: 100%;
}

.nav-item:hover .nav-e {
  display: block;
}

.nav-item.router-link-active::before {
    width: 100%;
}

.nav-item.router-link-active .nav-e {
  display: block;
}


</style>
