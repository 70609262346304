<template>
  <svg class="icon" aria-hidden="true">
    <use :xlink:href="'#' + name"/>
  </svg>
</template>

<script>
export default {
  name: 'icon',
  props: {
    name: {
      type: String,
      required: true
    }
  }
}
</script>