<template>
  <Header/>
  <div class="header-empty" />
  <router-view/>
  <Footer/>
</template>

<script>
import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"

export default {
  components: {
    Header,
    Footer
  }
}
</script>

<style>
body {
  margin: 0;
  overflow-x: hidden;
}

.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.header-empty {
  height: 80px;
}

.conten {
  max-width: 1230px;
  min-width: 960px;
  margin-right: auto;
  margin-left: auto;
}

.float-clear::after {
  content: ' ';
  clear: both;
  display: block;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

a {
  text-decoration: none;
}

.popup-parent--hidden {
  overflow: hidden;
  padding-right: 17px;
}

</style>
